import Vue from 'vue';
import Component from 'vue-class-component';

export type IFormValidations = { [key: string]: () => boolean; };

@Component
export default class FormValidationMixin extends Vue {
  showFormErrors = false;    
  formValidations: IFormValidations = {};

  inputState(rule:boolean): boolean | null {
    return this.showFormErrors ? rule : null;
  }

  validateForm(): boolean {
    const isValid = Object.values(this.formValidations).reduce((acc: boolean, v) => acc && v(), true);
    this.showFormErrors = !isValid;
    return isValid;
  }
}