
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ModalFormHeader extends Vue {  
  @Prop(String) private title?: string;
  @Prop(String) private icon?: string;

  close(): void {
    this.$emit('closed'); 
  }
}
